import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MapService } from './services/map.service';

@Component({
  selector: 'fw-map',
  templateUrl: './fw-map.component.html',
}) export class FwMapComponent implements  AfterViewInit {

  constructor(public mapService: MapService) { }

  @ViewChild('homMap', { read: ElementRef }) public mapEl: ElementRef;

  initMap(): void {
    if (!this.mapService.data.latitude) {
      new google.maps.Geocoder().geocode({ 'address': this.mapService.data.address }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const map: google.maps.Map = new google.maps.Map(this.mapEl.nativeElement, {
            center: results[0].geometry.location,
            zoom: 15
          });
          new google.maps.Marker({
            position: results[0].geometry.location,
            map: map,
            title: this.mapService.data.address
          });

        }
      })
    } else {
      const coordinates: google.maps.LatLng = new google.maps.LatLng(this.mapService.data.latitude, this.mapService.data.longitude),
        map: google.maps.Map = new google.maps.Map(this.mapEl.nativeElement, {
          center: coordinates,
          zoom: 15
        });
      new google.maps.Marker({
        position: coordinates,
        map: map,
        title: this.mapService.data.address
      });
    }
  }

  ngAfterViewInit(): void {
    if (!this.mapService.initialized) {
      const script: HTMLScriptElement = document.createElement("script");
      script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyBp1RpZgB5p1O67vdGZu7hu7i8ZYR6_q4I";
      //script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyBp1RpZgB5p1O67vdGZu7hu7i8ZYR6_q4I&loading=async"
      script.type = "text/javascript";
      script.onload = () => {
        this.initMap();
      }
      this.mapEl.nativeElement.appendChild(script);
      this.mapService.initialized = true;
    } else {
      this.initMap();
    }
  }

}
