import { Component, OnInit, OnDestroy,  Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';

//store actions, reducers, interfaces
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import { IAllDynamicData } from '../../../../../fw/dynamic-list/store/index';
import { IProviderLocation, IBranch, ISeedContactInformation } from '../../../view-models';
import { IErrorData, IResponseBase } from '../../../../../shared/interfaces';
import { ButtonType } from '../../../../../fw/fw-shared/enums/button-type.enum';
import { ContactEvent } from '../../../../contact/enums/contact.enums';

import { UserPriviledgesService } from '../../../../../auth/services';
import { ContactConstantsService, ContactUtilityService } from '../../../../contact/services';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { DomainObjectService } from '../../../../../shared/services';
import { AdminProviderService } from '../../services/admin-provider.service';

@Component({
  selector: 'admin-contact',
 templateUrl: './admin-contact.component.html'
})
export class AdminContactComponent implements OnInit, OnDestroy  {
  public parentId: number;
  public contactTypeId: number;
  public contactId$: BehaviorSubject<number> = new BehaviorSubject(0);;
  public operation$: BehaviorSubject<string> = new BehaviorSubject('');;
  public isOrganization: boolean = true;
  public canEdit: boolean = true;
  //set to false for admin contact (branches and locations should not look for existing contacts)
  //public checkForMatch$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);;
  parentObj: IBranch | IProviderLocation = null;
  originalContactId: number;
  defaults: ISeedContactInformation;
  subscription: Subscription = new Subscription();

  constructor(
    public activeRoute: ActivatedRoute,
    public store: Store<IAllDynamicData>,
    public ups: UserPriviledgesService,
    public adminService: AdminProviderService,
    public contactConstantsService: ContactConstantsService,
    public modalService: ModalService,
    public dos: DomainObjectService,
    public contactUtilityService: ContactUtilityService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.subscription.add(this.activeRoute.paramMap.subscribe(paramMap => {
      this.parentId = +paramMap.get('parentId');
      this.contactId$.next(+paramMap.get('id'));
      this.contactTypeId = +paramMap.get('typeId');
      //this.checkForMatch$.next(this.contactId$.value === 0);
      this.getParent();
    }));

    this.subscription.add(this.contactId$.subscribe((id: number) => {
      this.operation$.next(id === 0 ? this.myConstants.operationTypeCreate : this.myConstants.operationTypeDetails);
    }));
  }

  public onCrudEvent(event: IHomEventEmitter) {
    switch (event.event) {
      case ButtonType.cancel:
        this.contactId$.next(this.originalContactId);
        //this.checkForMatch$.next(false);
        break;
      case this.myConstants.emitterEventUpdateComplete:
        //this.checkForMatch$.next(false);
        this.requestReload(this.contactId$.value);
        break;
      case this.myConstants.emitterEventCreateComplete:
        this.contactId$.next(event.data);
        this.updateParentContact(event.data);
        break;
      case ContactEvent.linkContactToParent:
        //if (this.contactId$.value === 0) detail-contact will call save on contact manager.
        if (this.contactId$.value > 0) {
          this.updateParentContact(event.data);
        }
        break;
      case ContactEvent.unLinkContactToParent:
        //this.checkForMatch$.next(true);
        break;
      case ContactEvent.selectMatch:
        this.contactId$.next(event.data ? event.data.contactId : 0);
        break;
      default:
        break;
    }
  }

  getParent(): void {
    const parentListDef = this.contactTypeId === this.contactConstantsService.contactTypeIdBranch
      ? this.adminService.loadBranchesListDefinition()
      : this.contactTypeId === this.contactConstantsService.contactTypeIdProviderLocation
        ? this.adminService.loadProviderLocationsListDefinition()
        : null;

    this.subscription.add(this.store.pipe(
      select(fromDynamicList.getSelectedRecord(parentListDef.storeName, -1, parentListDef.rowKeyId, this.parentId)))
      .subscribe(entity => {
       this.parentObj = entity;
        this.contactId$.next(this.contactTypeId === this.contactConstantsService.contactTypeIdBranch
          ? this.parentObj['branchContact_contactId'] || 0
          : this.contactTypeId === this.contactConstantsService.contactTypeIdProviderLocation
            ? this.parentObj['providerLocationContact_contactId'] || 0
           : -1);
        this.originalContactId = this.contactId$.value;
        if (this.parentObj) {
          this.defaults = {
            contactId: 0,
            firstName: this.contactTypeId === this.contactConstantsService.contactTypeIdBranch
              ? 'Branch'
              : this.contactTypeId === this.contactConstantsService.contactTypeIdProviderLocation
                ? 'Provider Location' : 'Organization',
            middleName: '',
            lastName: this.contactTypeId === this.contactConstantsService.contactTypeIdBranch
              ? this.parentObj['branchName']
              : this.contactTypeId === this.contactConstantsService.contactTypeIdProviderLocation
                ? this.parentObj['locationName'] : '',
            birthDate: '',
            sssOptOut: true,
            optOutSms: true,
            contactOptOutSms: true,
            contactMechanismAddresses: [],
            contactMechanismEmails: [],
            contactMechanismPhones: [],

          }

        }
      })
    );
  }

  updateParentContact(contactId: number): void {
    const params: string = this.parentId + '?contactId=' + contactId.toString();
    const controllerName: string = this.contactTypeId === this.contactConstantsService.contactTypeIdBranch ? 'Branch' : 'ProviderLocation';

    this.errorData$.next([]);
    this.subscription.add(this.dos.updateByMethodParams(controllerName, 'UpdateContact', params)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          //this.checkForMatch$.next(false);
          this.requestReload(contactId);
        } else {
          this.errorData$.next(response.errorData);
        }
      }));
  }

  requestReload(contactId): void {
    const parentListDef = this.contactTypeId === this.contactConstantsService.contactTypeIdBranch
      ? this.adminService.loadBranchesListDefinition()
      : this.contactTypeId === this.contactConstantsService.contactTypeIdProviderLocation
        ? this.adminService.loadProviderLocationsListDefinition()
        : null;
    if (parentListDef) {
      const emitter: IHomEventEmitter = { requestor: 'admin-contact', event: this.myConstants.emitterEventListReload, action: '', data: null };
      this.store.dispatch(new DynamicListActions.SetEventList({
        storeName: parentListDef.storeName,
        parentId: parentListDef.parentId,
        event: emitter
      }));
    }
    this.modalService.close();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
